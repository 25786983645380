import homeVue from './../../components/homepage/Homepage.vue'
import listVue from './../../components/list/List.vue'
import articlesDetailsVue from './../../components/articlesDetails/ArticleDetails.vue'
import privatepolicyVue from './../../components/privatepolicy/Privatepolicy.vue'

const _routes = [
  {
    name: 'home', path: '/', component: homeVue, children: [
      {
        name: 'privatepolicy',
        path: '/polityka-prywatnosci',
        component: privatepolicyVue,
        props: true
      }
    ], props: true
  },
  { name: 'blog', path: '/blog', component: listVue },
  { name: 'slug', path: '/:slug', component: articlesDetailsVue, props: true }
]

export default {
  getRoutes (cb, site) {
    setTimeout(() => { cb(_routes), site('maesto') }, 100)
  }
}
