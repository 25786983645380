/**
 * Mocking client-server processing
 */
const _products = []

export default {
  getProducts (cb) {
    setTimeout(() => cb(_products), 100)
  }
}
