/**
 * Mocking client-server processing
 */
const _articles = {
  'id': 1,
  'title': 'Broszki damskie.',
  'meta': 'Sklepy jubilerskie proponują broszki damskie w dowolnych kolorach, kształtach oraz z różnych materiałów np. ze srebra, złota, ażuru, czyli metalu, kości słoniowej, tworzywa sztucznego, z kamienia bądź z kilku drobnych elementów tworzących mozaikę. ',
  'image': {
    'src': 'https://broszki.damskie.eu/images/thumb/broszki/broszki-damskie-300x500.png',
    'alt': 'broszki damskie.',
    'source': 'https://broszki.damskie.eu/images/thumb/broszki/broszki-damskie-200x400.png',
    'source2': 'https://broszki.damskie.eu/images/thumb/broszki/broszki-damskie-200x400.png',
    'original': 'https://broszki.damskie.eu/images/broszki/broszki-damskie.png'
  },
  'video': [
    {
      'show': 'false',
      'link': ''
    }
  ],
  'lead': '',
  'content': [
    { 'section': 'Kobiety uwielbiają różnego rodzaju biżuterię. Jedna z takich błyskotek jest broszka, która zazwyczaj kojarzy się z arystokracją lub też odnosi się wspomnieniami do naszych kochanych babć.' },
    { 'section': '<h2>Historia broszki</h2>' },
    { 'section': 'Początki tego małego detalu w garderobie przypadają już na czasy starożytne, gdyż miała ona wiele wzlotów i upadków. Według dostępnych źródeł nazwa jej pochodzi od łacińskiego słowa fibula oznaczająca zapinkę lub agrafkę. Służyła jako element do spinania szat, sukni a dużo później bluzki pod szyją. Obecnie ma zastosowanie jako element dodający naszej garderobie stylu i elegancji.' },
    { 'section': '<h2>Charakterystyka ozdoby</h2>' },
    { 'section': 'Dzisiejsze sklepy jubilerskie proponują broszki damskie w dowolnych kolorach, kształtach oraz z różnych materiałów np. ze srebra, złota, ażuru, metalu, kości słoniowej, tworzywa sztucznego, z kamienia bądź kilku drobnych elementów tworzących mozaikę. Ostatnio popularne stały się też broszki wykonane z drewna, filcu lub z kamei (płaskorzeźba przedstawiająca jakiś konkretny motyw). Dodatkowo trzeba wspomnieć, że ozdoba ta posiada też różne zapięcia m.in. agrafkę, igłę lub klamrę.' },
    { 'section': '<h2>Kilka reguł noszenia broszki</h2>' },
    { 'section': 'Po pierwsze ten rodzaj kobiecego drobiazgu dodaje nowego blasku starej apaszce czy żakietowi oraz pasuje do każdego typu figury.' },
    { 'section': 'Po drugie ta elegancka ozdoba pasuje też do chusty, szala, swetra, sukienki czy koszuli ze stójką.' },
    { 'section': 'Srebrne broszki są adekwatne na prezent lub na wieczorowe imprezy czy spotkania.' },
    { 'section': 'Zadaniem tej biżuterii jest odwrócenie uwagi od innych rzeczy lub mankamentów naszej urody.' },
    { 'section': 'Najczęściej są usytuowane na górnej części naszej klatki piersiowej, czyli pod obojczykiem, przy ramionach czy w centrum dekoltu.' },
    { 'section': 'Na domiar tego nie tylko nadaje się na klapę żakietu, ale i na kieszeń lub pas od spódnicy.' },
    { 'section': 'W przypadku kobiet posiadających obfity biust ważną wskazówką jest to, aby w jego obrębie nie nosić dużych broszek, lecz mniejsze i skromniejsze.' },
    { 'section': 'Kolejną ważną ciekawostką dotyczącą tej ozdoby jest to, że nie wymaga ona innych elementów takich jak wisiorki, bransoletki czy kolczyki. Do nowoczesnych stylizacji, geometrycznych kostiumów należy wybrać biżuterię, aby charakteryzowała się minimalistyczną formą i kolorem, natomiast na romantyczną kolację, przyjęcie koktajlowe nadaje się ozdoba z konkretnym motywem np. kwiatu, ptaka.' },
    { 'section': 'Reasumując, broszka to taki element, który nie tylko nada charakteru, stylu i elegancji naszej stylizacji, ale i doda pewności siebie, dlatego warto posiadać taką biżuterię w swojej szkatułce.' },
    { 'section': 'Pozdrawia zespół broszki.damskie.eu' }
  ],
  'slug': '',
  'outerlink': ''
}

export default {
  getArticles (cb) {
    setTimeout(() => cb(_articles), 100)
  }
}
