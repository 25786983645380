






import Vue from "vue";
import Component from "vue-class-component";
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'

import DetailsLinkToArt from "./../articlesDetails/DetailsLinkToArt.vue";

@Component({
  components: { DetailsLinkToArt }
})
export default class Articles extends Vue {
  @State(state => state.hole) stateArticles
  @Action('hole/getAllArticles') actionGetAllArticles

  mounted () {
    this.actionGetAllArticles()
  }

  get arts() {
    return this.stateArticles.articles
  }
};
