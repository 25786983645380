









































































import Vue from "vue";
import Component from "vue-class-component";
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'

import Products from "./../products/Products.vue";

@Component({
  components: { Products },
  props: ["global"],
})
export default class HomeArticle extends Vue {
  @State(state => state.hole) stateArticles
  @Action('hole/getAllArticles') actionGetAllArticles
  @Action('hole/getProducts') actionGetProducts

  mounted () {
    this.actionGetAllArticles()
    this.actionGetProducts()
  }

  get arts() {
    return this.stateArticles.articles
  }

  get products() {
    return this.stateArticles.products
  }
};
