




import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: ['detailsLink', 'global']
})
export default class DetailsLinkToArt extends Vue {

  mounted () {
    // console.log(this);
  }
};
