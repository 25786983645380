const _header = [
  {
    'logo': 'https://broszki.damskie.eu/images/logo/muchy-logo.svg',
    'logo_alt': 'broszki damskie',
    'class': 'shop-meskie',
    'style': '3',
    'size': '12'
  }
]

export default {
  getHeader (cb) {
    setTimeout(() => cb(_header), 100)
  }
}
