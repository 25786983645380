const _footer = [
  {
    'top': [
      {
        'name': 'Muchy męskie',
        'link': 'https://muchy.meskie.eu/',
        'size': '3'
      },
      {
        'name': 'Spinki męskie do mankietów',
        'link': 'https://spinki.meskie.eu/',
        'size': '3'
      },
      {
        'name': 'Broszki damskie',
        'link': 'https://broszki.damskie.eu/',
        'size': '3'
      }
    ],
    'copyright': [
      {
        'id': '1',
        'content': 'Copyright 2019 <a href="https://damskie.eu/" target="_blank" title="Strona z artykułami lifestyle.">damskie.eu</a>',
        'size': '8'
      },
      {
        'id': '2',
        'content': '<a href="mailto:biuro@damskie.eu" title="Skontaktuj się z nami przez e-mail.">biuro@damskie.eu</a>',
        'size': '4'
      },
      {
        'id': '3',
        'content': null
      },
      {
        'id': '4',
        'content': null
      }
    ],
    'class': 'shop-meskie',
    'style': '3'
  }
]

export default {
  getFooter (cb) {
    setTimeout(() => cb(_footer), 100)
  }
}
