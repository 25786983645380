






import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";

@Component({
  props: ["global"],
  metaInfo() {
    return {
      title: this.policyDetails.title,
      meta: [
        {
          name: "robots",
          content: "noindex"
        },
        {
          name: "description",
          content: this.policyDetails.meta
        },
        {
          name: "keywords",
          content: this.policyDetails.keywords
        },
        {
          property: "og:url",
          content: this.global.url
        },
        {
          property: "og:title",
          content: this.global.title
        },
        {
          property: "og:description",
          content: this.global.meta
        },
        {
          property: "og:image",
          content: this.global.image
        }
      ],
      link: [{ rel: "icon", href: this.global.favicon, type: "image/x-icon" }],
    };
  },
})
export default class Privatepolicy extends Vue {
  @Prop() details!: object;
  @Prop() global!: any;

  @State(state => state.hole) statePrivatepolicy;
  @Action("hole/getPrivatepolicy") actionGetPrivatepolicy;

  loadAsyncData() {
    this.actionGetPrivatepolicy();
    return this.statePrivatepolicy.privatepolicy;
  }

  get policyDetails() {
    let x: object;

    if (this.details) {
      x = this.details;
    } else {
      x = this.loadAsyncData();
    }
    return x;
  }
}
