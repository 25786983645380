import Vue from 'vue'
import Vuex from 'vuex'
import hole from './modules/hole'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    hole
  }
})
