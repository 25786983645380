









import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Last } from "./../../types/types";

@Component({
  props: ["global", "product"],
})
export default class Products extends Vue {}
