









import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";

@Component({
  props: ["global"],
  metaInfo() {
    return {
      title: this.details.title,
      meta: [
        {
          name: "description",
          content: this.details.meta
        },
        {
          property: "og:url",
          content: this.global.url + '/' + this.details.slug
        },
        {
          property: "og:title",
          content: this.details.title
        },
        {
          property: "og:description",
          content: this.details.meta
        },
        {
          property: "og:image",
          content: this.global.image
        }
      ],
      link: [, { rel: "icon", href: this.global.favicon, type: "image/x-icon" }]
    };
  }
})
export default class ArticleDetails extends Vue {
  @Prop() articleDetails!: object;
  @Prop() global!: any;

  @State(state => state.hole) stateSingle;
  @Action("hole/getSingleArt") actionGetSingleArt;

  loadAsyncData() {
    this.actionGetSingleArt(this.$route.params.slug);
    console.log(this.$route.params.slug)
    return this.stateSingle.single;
  }

  get details() {
    let x: object;

    if (this.articleDetails) {
      x = this.articleDetails;
    } else {
      x = this.loadAsyncData();
    }
    return x;
  }
}
