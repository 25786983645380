import { render, staticRenderFns } from "./ArticleDetails.vue?vue&type=template&id=74f183fa&lang=pug&"
import script from "./ArticleDetails.vue?vue&type=script&lang=ts&"
export * from "./ArticleDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ArticleDetails.vue"
export default component.exports