






















import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import LastArticles from "./../articles/LastArticles.vue";
import HomeArticle from "./../articles/HomeArticle.vue";
import Regulations from "./../regulations/Regulations.vue";
import Privatepolicy from "./../privatepolicy/Privatepolicy.vue";

@Component({
  props: ["global"],
  metaInfo() {
    return {
      title: this.global.title,
      meta: [
        {
          name: "description",
          content: this.global.meta
        },
        {
          name: "keywords",
          content: this.global.keywords
        },
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "author",
          content: "Przemek Ząbek"
        },
        {
          itemprop: "name",
          content: this.global.title
        },
        {
          itemprop: "alternateName",
          content: this.global.alternate
        },
        {
          itemprop: "url",
          content: "https://" + this.global.url + "/"
        },
        {
          property: "og:url",
          content: this.global.url
        },
        {
          property: "og:title",
          content: this.global.title
        },
        {
          property: "og:description",
          content: this.global.meta
        },
        {
          property: "og:image",
          content: this.global.image
        }
      ],
      link: [
        { rel: "icon", href: this.global.favicon, type: "image/x-icon" },
        { rel: "canonical", href: 'https://' + this.global.url + '/' },
        { rel: "publisher", href: 'https://mitzero.pl/' }
        ],
      script: [
        { innerHTML: '{"@context": "http://schema.org/", "@type": "Person", "name": "Przemek Ząbek", "alternateName": "Mitzero", "url": "https://mitzero.pl/","image": "https://mitzero.pl/wp-content/uploads/2018/09/mitzero-logo.png", "jobTitle": "Webdeveloper", "worksFor": { "@type": "Organization", "name": "Mitzero" }}', type: 'application/ld+json' }
      ]
    };
  },
  components: { LastArticles, HomeArticle, Regulations, Privatepolicy }
})
export default class Homepage extends Vue {
  @Prop() showLastArticles: boolean = false;
  @Prop() showRegulations: boolean = false;
  @Prop() showPrivatepolicy: boolean = false;

  @Watch("$route", { immediate: true })
  OnRouteChange(route: any) {
    // console.log(route.name);
    if (route.name === "regulations") {
      this.showRegulations = true;
      this.showLastArticles = false;
      this.showPrivatepolicy = false;
    } else if (route.name === "privatepolicy") {
      this.showRegulations = false;
      this.showLastArticles = false;
      this.showPrivatepolicy = true;
    } else {
      this.showLastArticles = true;
    }
  }
}
