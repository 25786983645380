






import Vue from "vue";
import Component from "vue-class-component";
import Articles from "./../articles/Articles.vue";

@Component({ 
  components: { Articles }
})
export default class List extends Vue {
  title: string;

  constructor() {
    super();
    this.title = "Lista";
    // console.log("xxx");
  }
}
