// // wbudowie
// import getRegulations from '../../api/wbudowie/regulations'
// import getPrivatepolicy from '../../api/wbudowie/privatepolicy'
// import getGlobal from '../../api/wbudowie/global'
// import getHeader from '../../api/wbudowie/header'
// import getNav from '../../api/wbudowie/nav'
// import getArts from '../../api/wbudowie/articles'
// import getFooter from '../../api/wbudowie/footer'
// import getProducts from '../../api/wbudowie/products'
// blogmaesto
// import getRegulations from '../../api/blogmaesto/regulations'
// import getPrivatepolicy from '../../api/blogmaesto/privatepolicy'
// import getGlobal from '../../api/blogmaesto/global'
// import getHeader from '../../api/blogmaesto/header'
// import getNav from '../../api/blogmaesto/nav'
// import getArts from '../../api/blogmaesto/articles'
// import getFooter from '../../api/blogmaesto/footer'
// muchymeskie
// import getPrivatepolicy from '../../api/muchymeskie/privatepolicy'
// import getGlobal from '../../api/muchymeskie/global'
// import getHeader from '../../api/muchymeskie/header'
// import getNav from '../../api/muchymeskie/nav'
// import getArts from '../../api/muchymeskie/articles'
// import getFooter from '../../api/muchymeskie/footer'
// import getProducts from '../../api/muchymeskie/products'
// spinkimeskie
// import getPrivatepolicy from '../../api/spinkimeskie/privatepolicy'
// import getGlobal from '../../api/spinkimeskie/global'
// import getHeader from '../../api/spinkimeskie/header'
// import getNav from '../../api/spinkimeskie/nav'
// import getArts from '../../api/spinkimeskie/articles'
// import getFooter from '../../api/spinkimeskie/footer'
// import getProducts from '../../api/spinkimeskie/products'
// broszki damskie
import getPrivatepolicy from '../../api/broszkidamskie/privatepolicy'
import getGlobal from '../../api/broszkidamskie/global'
import getHeader from '../../api/broszkidamskie/header'
import getNav from '../../api/broszkidamskie/nav'
import getArts from '../../api/broszkidamskie/articles'
import getFooter from '../../api/broszkidamskie/footer'
import getProducts from '../../api/broszkidamskie/products'

import { State } from '../../types/types'

// initial state
const state: State = {
  regulations: [],
  privatepolicy: [],
  global: null,
  header: null,
  nav: null,
  articles: [],
  articlesLast: [],
  single: [],
  footer: null,
  products: []
}

// // mutations
const mutations = {
  SET_GLOBAL: (state, global) => {
    state.global = global[0]
  },
  SET_HEADER: (state, header) => {
    state.header = header[0]
  },
  SET_NAV: (state, nav) => {
    state.nav = nav[0]
  },
  SET_ARTICLES: (state, arts) => {
    state.articles = arts
  },
  SET_LAST_ARTICLES: (state, arts) => {
    state.articlesLast = arts
  },
  SET_SINGLE_ART: (state, { arts, slug }) => {
    const art = arts.filter((item) => {
      if (slug === item.slug) return item
    })
    state.single = art[0]
  },
  SET_REGULATIONS: (state, regulations) => {
    state.regulations = regulations[0]
  },
  SET_PRIVATEPOLICY: (state, privatepolicy) => {
    state.privatepolicy = privatepolicy[0]
  },
  SET_FOOTER: (state, footer) => {
    state.footer = footer[0]
  },
  SET_PRODUCTS: (state, products) => {
    state.products = products
  }
}

// actions
const actions = {
  getGlobal ({ commit }) {
    getGlobal.getGlobal(global => {
      commit('SET_GLOBAL', global)
    })
  },
  getHeader ({ commit }) {
    getHeader.getHeader(header => {
      commit('SET_HEADER', header)
    })
  },
  getNav ({ commit }) {
    getNav.getNav(nav => {
      commit('SET_NAV', nav)
    })
  },
  getAllArticles ({ commit }) {
    getArts.getArticles(arts => {
      commit('SET_ARTICLES', arts)
    })
  },
  getLastArticles ({ commit }, slug: string) {
    getArts.getArticles(arts => {
      commit('SET_LAST_ARTICLES', arts, slug)
    })
  },
  getSingleArt ({ commit }, slug: string) {
    getArts.getArticles(arts => {
      commit('SET_SINGLE_ART', { arts, slug })
    })
  },
  getFooter ({ commit }) {
    getFooter.getFooter(footer => {
      commit('SET_FOOTER', footer)
    })
  },
  getRegulations ({ commit }) {
    getRegulations.getRegulations(regulations => {
      commit('SET_REGULATIONS', regulations)
    })
  },
  getPrivatepolicy ({ commit }) {
    getPrivatepolicy.getPrivatepolicy(privatepolicy => {
      commit('SET_PRIVATEPOLICY', privatepolicy)
    })
  },
  getProducts ({ commit }) {
    getProducts.getProducts(products => {
      commit('SET_PRODUCTS', products)
    })
  }
}

// getters
const getters = {
  getRegulations: state => {
    return state.regulations
  },
  getPrivatepolicy: state => {
    return state.privatepolicy
  },
  getArticles: state => {
    return state.articles
  },
  getLastArticles: state => {
    return state.articlesLast
  },
  getFooter: state => {
    return state.footer
  },
  getProducts: state => {
    return state.products
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
