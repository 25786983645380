






import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";

@Component({
  props: ["global"],
  metaInfo() {
    return {
      title: this.regDetails.title,
      meta: [
        {
          name: "robots",
          content: "noindex"
        },
        {
          name: "description",
          content: this.regDetails.meta
        },
        {
          name: "keywords",
          content: this.regDetails.keywords
        },
        {
          property: "og:url",
          content: this.global.url
        },
        {
          property: "og:title",
          content: this.global.title
        },
        {
          property: "og:description",
          content: this.global.meta
        },
        {
          property: "og:image",
          content: this.global.image
        }
      ],
      link: [{ rel: "icon", href: this.global.favicon, type: "image/x-icon" }],
    };
  },
})
export default class Regulations extends Vue {
  @Prop() details!: object;
  @Prop() global!: any;

  @State(state => state.hole) stateRegulations;
  @Action("hole/getRegulations") actionGetRegulations;

  loadAsyncData() {
    this.actionGetRegulations();
    return this.stateRegulations.regulations;
  }

  get regDetails() {
    let x: object;

    if (this.details) {
      x = this.details;
    } else {
      x = this.loadAsyncData();
    }
    return x;
  }
}
