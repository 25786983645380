













// import "./styles/global/global.scss";

import Vue from "vue";
import Component from "vue-class-component";

import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Emit } from "vue-property-decorator";

import CookieLaw from 'vue-cookie-law';
import Regulations from "./components/regulations/Regulations.vue";
import Privatepolicy from "./components/privatepolicy/Privatepolicy.vue";
import Header from "./components/header/Header.vue";
import Homepage from "./components/homepage/Homepage.vue";
import List from "./components/list/List.vue";
import Footer from "./components/footer/Footer.vue";

@Component({
  components: { Header, Homepage, List, Regulations, Privatepolicy, Footer, CookieLaw },
})
export default class App extends Vue {
  @State(state => state.hole) stateGlobal;
  @Action("hole/getGlobal") actionGetGlobal;

  // styleUrl: string;

  // constructor() {
  //   super();
  //   this.styleUrl = "";
  // }

  // loadStyle() {
  //   const x = this.actionGetGlobal();
  //   x.then(result => {
  //     if (x) this.styleUrl = this.stateGlobal.global.style;
  //     // require("./styles/container/container-style-" + this.styleUrl + ".scss");
  //     // require("./styles/content/content-style-" + this.styleUrl + ".scss");
  //   }).catch(err => {});
  // }

  created() {
    this.actionGetGlobal();
  }

  loadAsyncData() {
    this.actionGetGlobal();
    return this.stateGlobal.global;
  }

  get global() {
    // this.loadStyle();
    return this.loadAsyncData();
  }
}
