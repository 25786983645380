











import Vue from "vue";
import Component from "vue-class-component";
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import { Prop, Emit  } from 'vue-property-decorator'

@Component
export default class Nav extends Vue {
  @State(state => state.hole) stateNav;
  @Action("hole/getNav") actionGetNav;

  created() {
    this.actionGetNav();
  }

  loadAsyncData () {
    this.actionGetNav();
    return this.stateNav.nav;
  }

  get nav () {
    return this.loadAsyncData();
  }
};
