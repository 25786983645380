const _nav = [
  {
    'class': 'shop-meskie',
    'style': '3',
    'size': '8',
    'primaryMenu': []
  }
]

export default {
  getNav (cb) {
    setTimeout(() => cb(_nav), 100)
  }
}
