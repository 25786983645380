
























import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Last } from "./../../types/types";
import Striptags from "striptags";

import DetailsLinkToArt from "./../articlesDetails/DetailsLinkToArt.vue";

@Component({
  components: { DetailsLinkToArt },
  props: ["global"],
  filters: {
    truncate: function(text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  }
})
export default class LastArticles extends Vue {
  @State(state => state.hole) stateArticlesLast;
  @Action("hole/getLastArticles") actionGetLastArticles;

  // styleUrl: string;
  // global: any;

  // constructor() {
  //   super();
  //   this.styleUrl = this.global.style;
  // }

  // loadStyle() {
  //   require("./../../styles/articles-last/articles-last-style-" +
  //     this.styleUrl +
  //     ".scss");
  // }

  mounted() {
    this.actionGetLastArticles();
  }

  get arts() {
    // this.loadStyle();
    const data = this.stateArticlesLast.articlesLast;
    const readyData = data.map(element => {
      let changeLead = Striptags(element.lead);
      element.lead = changeLead
      return element
    });
    return readyData;
  }
}
