










import Vue from "vue";
import Component from "vue-class-component";
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import { Prop, Emit  } from 'vue-property-decorator'
import Nav from "./../nav/Nav.vue";

@Component({
  components: { Nav },
  props: ["global"],
})
export default class Header extends Vue {
  @State(state => state.hole) stateHeader;
  @Action("hole/getHeader") actionGetHeader;

  styleUrl: string;

  constructor() {
    super();
    this.styleUrl = "";
  }

  loadStyle() {
    const x = this.actionGetHeader();
    x.then(result => {
      if (x) this.styleUrl = this.stateHeader.header.style;
      require("./../../styles/main-" + this.styleUrl + ".scss");
      // require("./../../styles/header/header-style-" + this.styleUrl + ".scss");
    }).catch(err => {});
  }

  created() {
    this.actionGetHeader();
  }

  loadAsyncData () {
    this.actionGetHeader()
    return this.stateHeader.header
  }

  get header () {
    this.loadStyle();
    return this.loadAsyncData();
  }
}
